<template>
  <v-card-title>
    <v-spacer v-if="!closeBtn" />
    <!-- title -->
    <span style="cursor: default;" class="text-subtitle-1 font-weight-bold" :class="closeBtn?'text--secondary':'primary--text'" v-html="title" />

    <v-spacer />

    <!-- delete btn -->
    <v-tooltip 
      v-if="deleteBtn"
      color="error" 
      :nudge-left="nudgeLeft"
      :nudge-top="nudgeTop"
      :max-width="maxWidth"
      :min-width="minWidth"
      :bottom="isBottom"
      :left="!isBottom"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <v-btn small v-bind="attrs" v-on="on" :color="hover ? 'error' : ''" icon @click="$emit('delete')" class="me-2 pa-0">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-hover>
      </template>
      <span class="text-caption font-weight-bold" v-text="deleteMsg" />
    </v-tooltip>

    <!-- description btn -->
    <v-tooltip
      v-if="help.length"
      :color="color"
      :nudge-left="nudgeLeft"
      :nudge-top="nudgeTop"
      :max-width="maxWidth"
      :min-width="minWidth"
      :bottom="isBottom"
      :left="!isBottom"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn small icon v-bind="attrs" v-on="on" :color="color" class="ma-0 pa-0">
          <v-icon>mdi-lightbulb-on-outline</v-icon>
        </v-btn>
      </template>
      <span class="text-caption font-weight-bold" v-text="help" />
    </v-tooltip>

    <!-- close btn -->
    <v-btn v-if="closeBtn" small icon color="error" @click="$emit('close')" class="ma-0 ms-2 pa-0">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-card-title>
</template>

<script>
export default {
  install(Vue) {
    Vue.component("Header", this);
  },
  props: {
    title: String,
    help: {
      type: String,
      default: "",
    },
    deleteMsg: {
      type: String,
      default: "",
    },
    deleteBtn: {
      type: Boolean,
      default: false,
    },
    closeBtn: {
      type: Boolean,
      default: true,
    },
    color:{
      type : String,
      default : 'warning'
    },
    isBottom: {
      type: Boolean,
      default: true,
    },
    "min-width": {
      type: String,
      default: "430",
    },
    "max-width": {
      type: String,
      default: "430",
    },
    "nudge-left": {
      type: Number,
      default: 0,
    },
    "nudge-top": {
      type: Number,
      default: 0,
    },
  },
};
</script>
