const ImageURLMixin = {
  methods: {
    // :src="$ImageAssets('flags', 'ir')"
    $ImageAssets(directory, file){
      let type = directory == 'flags' ? 'svg' : 'png'
      return require(`@/assets/images/${directory}/${file}.${type}`)
    }
  },
};

// export default ImageURL;
export {
  ImageURLMixin
}
