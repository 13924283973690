<template>
  <!-- <CopyToClipboard btnColor="red" :copy="project.project_title" /> -->

  <v-tooltip
    :bottom="tooltipBottom"
    :top="top"
    :left="tooltipLeft"
    :right="tooltipRight"
    :color="changeIcon?'#2CBE60':btnColor"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :icon="textMode ? false : true"
        :text="textMode ? true : false"
        :color="changeIcon?'#2CBE60':btnColor"
        @click="copyText"
        :small="btnSmall"
        :x-small="btnXSmall"
      >
        <v-icon :x-small="xSmall" :small="small" :large="large" v-if="!textMode" v-text="changeIcon?'mdi-check-circle-outline':'mdi-content-copy'" />
        <span v-else v-text="text" />
      </v-btn>
    </template>
    <small v-if="!textMode" class="font-weight-bold" v-text="changeIcon?$t('COPIED'):$t('COPY_TO_CLIPBOARD')" />
    <small v-else class="font-weight-bold" v-text="`${tooltipText}`" />
  </v-tooltip>
</template>

<script>
export default {
  install(Vue) {
    Vue.component("CopyToClipboard", this);
  },

  props: {
    copy: {
      type: String,
      default: null,
    },
    btnColor: {
      type: String,
      default: "primary",
    },
    btnXSmall: {
      type: Boolean,
      default: false,
    },
    btnSmall: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    tooltipBottom: {
      type: Boolean,
      default: false,
    },
    tooltipTop: {
      type: Boolean,
      default: false,
    },
    tooltipLeft: {
      type: Boolean,
      default: false,
    },
    tooltipRight: {
      type: Boolean,
      default: false,
    },
    textMode: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "Button text",
    },
    tooltipText: {
      type: String,
      default: "Tooltip text",
    },
  },

  data() {
    return {
      top: false,
      changeIcon: false
    };
  },

  computed: {
    getValue() {
      return this.copy;
    },
  },

  watch: {
    changeIcon: {
      immediate: true,
      handler() {
        if (this.changeIcon) {
          setTimeout(() => {
            this.changeIcon = false;
          }, 2000);
        }
      },
    },
  },

  mounted() {
    if (this.tooltipTop) this.top = true;
    else if (
      !this.tooltipBottom &&
      !this.tooltipTop &&
      !this.tooltipLeft &&
      !this.tooltipRight
    )
      this.top = true;
  },

  methods: {
    copyText() {
      try {
        if (navigator.clipboard && window.isSecureContext) {
          return navigator.clipboard.writeText(this.getValue);
        } else {
          var textArea = document.createElement("input");
          textArea.style.position = "fixed";
          textArea.style.left = "-999999px";
          textArea.style.top = "-999999px";
          textArea.value = this.getValue;
          // get modal copy clipboard
          var curr = document.getElementsByClassName("v-dialog");
          if (curr.length > 0) {
            curr[0].appendChild(textArea);
          } else {
            document.body.appendChild(textArea);
          }
          textArea.focus();
          textArea.select();
          textArea.setSelectionRange(0, 99999); // For mobile devices
          let copy = document.execCommand("copy");
          if (copy) {
            this.changeIcon = true
            // this.$store.commit("setSnackbarData", {
            //   position: 1,
            //   timeout: 1000,
            //   message: [this.$t("COPY_TO_CLIPBOARD_MASSAGE")],
            // });
          }
        }
      } catch (err) {
        // console.log("Oops, unable to copy");
      }
      textArea.remove();
    },
  },
};
</script>
