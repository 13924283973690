function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
};

function b64_to_utf8(str) {
    try {
        return decodeURIComponent(escape(window.atob(str)));
    } catch {
        return str;
    }
};

function vuetifySetter(vuetify) {
    var vuetifyThemeColors = Object.keys(vuetify.theme.themes);
    Object.keys(localStorage).forEach((key) => {
        try {
            let keyUTF8 = b64_to_utf8(key);
            vuetifyThemeColors.forEach((theme) => {
                if (keyUTF8.startsWith(`${theme}_`)) {
                    var splitKey = keyUTF8.split("_")[1];
                    vuetify.theme.themes[theme][splitKey] = b64_to_utf8(
                        localStorage[key]
                    );
                }
            });
        } catch {
            // TODO :
            // 1 - localStorage.clear()
            // 2 - 404 not found public page

        }
    });
};

function localStorageSetter(params) {
    // Color setter
    let colors = params.colors;
    delete params["colors"];
    Object.keys(colors).forEach((key) => {
        var parentKey = key;
        Object.entries(colors[key]).forEach(([key, value]) => {
            var newkey = `${parentKey}_${key}`;
            localStorage.setItem(
                utf8_to_b64(newkey),
                utf8_to_b64(value)
            );
        });
    });

    // others
    Object.keys(params).forEach((key) => {
        if (params[key] !== null) {
            Object.entries(params[key]).forEach(([key, value]) => {
                if (value !== null) {
                    localStorage.setItem(
                        utf8_to_b64(key),
                        utf8_to_b64(value)
                    );
                }
            });
        }
    });
};


export default {
    utf8_to_b64: utf8_to_b64,
    b64_to_utf8: b64_to_utf8,
    vuetifySetter: vuetifySetter,
    localStorageSetter: localStorageSetter,
};




