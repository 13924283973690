import Vue from "vue";
import VueRouter from "vue-router";

// handeling navigationduplicated Avoided redundant navigation to current location
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

var routes = [];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

var guests = [
  "Login",
  "Register",
  "Activation",
  "ForgotPassword",
  "ResetPassword",
];

var whiteList = [
  "Unsubscribe",
  "NotFound"
]
// var noAuthRequire = [
//   "NotFound", "ServerError",
// ];

var requiresAuth = [
  "ProjectSettings", "MyNumber", "MyModule", "BuyNumberCountryDid", 
  "Home", "Dashboard", "BuyNumberCountry", "BuyNumber", "CustomerCare", 
  "Account", "Finance", "Cart", "Sms", "OrderResult", "CallDetailRecord", "Rate",
];

router.beforeEach((to, from, next) => {
  // console.log('tooooooooo', to.name)
  if (to.name) {
    document.title = to.meta.title;
    if(!whiteList.includes(to.name) && !requiresAuth.includes(to.name) && !guests.includes(to.name)) {
      // console.log('Hooke 0 ==>', to.name, from.name)
      next({ name: "NotFound" })
    }
    else if(whiteList.includes(to.name)) {
      // console.log('whiteList')
      // console.log('Hooke whiteList ==>', to.name, from.name)
      next()
    }
    else if(requiresAuth.includes(to.name)) {
      if(!window.localStorage.getItem('token')) {
        // console.log('Hooke requiresAuth ==>', to.name, from.name)
        next({ name: "Login" });
      } else {
        // console.log('Hooke requiresAuth token ==>', to.name, from.name)
        next()
      }
    }
    else if(guests.includes(to.name)) {
      if(!window.localStorage.getItem('token')) {
        // console.log('Hooke guests ==>', to.name, from.name)
        next()
      } else {
        // console.log('Hooke guests token ==>', to.name, from.name)
        next({ name: "Home" });
      }
    }
    else {
      // console.log('Hooke 1 ==>', to.name, from.name)
      next();
    }
  }
  next();
})

router.afterEach((to, from) => {
  Vue.nextTick( () => {
    document.title = to.meta.title ? to.meta.title : 'Telobal';
  });
})

export default router;
