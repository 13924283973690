import Vue from "vue";
import Vuex from "vuex";
import i18n from "./i18n";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    overlay: {
      color: "",
      textColor: "",
      percent: 0,
      show: false,
      textOverlay: "",
    },
    themeName: null,
    siteKey: null,
    auth: false,
    persianInvoice: false,
    account: {},
    currency: {},
    appConfig: {},
    appAccess: {},
    appLoading: false,
    wellcomeNote: null,
    userAgent: {},
    currentProject: {},
    globalStatus: {},
    hideSidebar: false,
    minSidebar: false,
    forceChangePassword: false,
    verifiedPhone: false,
    errorText: null,
    languages: [],
    currentLang: "",

  },

  getters: {
    getAuth(state) {
      return state.auth
    },
    getAppAccess(state) {
      return state.appAccess
    },
    getHideSidebar(state) {
      return state.hideSidebar
    },
    getMinSidebar(state) {
      return state.minSidebar
    },
    getAvailableBalance(state) {
      // console.log("+state['currentProject']['available_balance']", state['currentProject']['available_balance'])
      // console.log("+state['currentProject']['minimum_balance']", state['currentProject']['minimum_balance'])
      return (+state['currentProject']['available_balance'])+(+state['currentProject']['minimum_balance'])
    },
    getState: (state) =>
      (key, value = null) => {
        if (value) {
          return state[key][value];
        }
        return state[key];
      },
  },

  mutations: {
    showOverlay(state, boolean) {
      if (boolean === false) {
        state.overlay = Object.assign({}, { show: false });
      } else {
        state.overlay.show = true;
      }
    },
    setOverlay(state, data) {
      state.overlay = Object.assign({}, data);
    },
    setThemeName(state, name) {
      state.themeName = name;
    },
    setSiteKey(state, key) {
      state.siteKey = key;
    },
    
    setAuth(state, boolean) {
      state.auth = boolean;
    },
    setPersianInvoice(state, boolean) {
      state.persianInvoice = boolean;
    },
    setAccount(state, data) {
      localStorage.setItem("language", data.language);
      state.account = Object.assign({}, data);
      // i18n.locale = data.language
    },
    setLanguages(state, lang) {
      state.languages = lang;
    },
    setCurrency(state, data) {
      state.currency = Object.assign({}, data);
    },
    setGlobalStatus(state, data) {
      state.globalStatus = Object.assign({}, data);
    },
    setAppConfig(state, data) {
      state.appConfig = Object.assign({}, data);
    },
    setAppAccess(state, data) {
      state.appAccess = Object.assign({}, data);
    },
    setWellcomeNote(state, note) {
      state.wellcomeNote = note;
    },
    setAppLoading(state, boolean) {
      state.appLoading = boolean;
    },
    setUserAgent(state, data) {
      state.userAgent = Object.assign({}, data);
    },
    setCurrentProject(state, project) {
      let walletData = {
        available_balance: state.currentProject.available_balance,
        balance: state.currentProject.balance,
        minimum_balance: state.currentProject.minimum_balance,
      }
      state.currentProject = Object.assign({}, project);
      if(!state.currentProject.hasOwnProperty('available_balance')) state.currentProject.available_balance = walletData.available_balance
      if(!state.currentProject.hasOwnProperty('balance')) state.currentProject.balance = walletData.balance
      if(!state.currentProject.hasOwnProperty('minimum_balance')) state.currentProject.minimum_balance = walletData.minimum_balance
    },
    updateCurrentProject(state, project) {
      let keys = [
        "logo",
        "name",
        "experimental",
        "staff_out_call_strategy",
        "timezone",
        "voice_language",
        "billing_address_country",
      ]
      keys.forEach((key) => {
        if(project.hasOwnProperty(key)) state.currentProject[key] = project[key]
      });
    },
    setHideSidebar(state, hide) {
      state.hideSidebar = hide
    },
    setMinSidebar(state, min) {
      state.minSidebar = min
    },
    setForceChangePassword(state, boolean) {
      state.forceChangePassword = boolean
    },
    setVerifiedPhone(state, boolean) {
      state.verifiedPhone = boolean
    },
    setErrorText(state, str) {
      state.errorText = str
    },
    badgeCount(state, number) {
      state.currentProject.items_in_cart += number
    },
    updateBalance(state, available_balance) {
      state.currentProject.available_balance = available_balance
    },
    updateBalanceObject(state, balance) {
      if(balance.hasOwnProperty('available_balance')) state.currentProject.available_balance = balance.available_balance
      if(balance.hasOwnProperty('balance')) state.currentProject.balance = balance.balance
      if(balance.hasOwnProperty('minimum_balance')) state.currentProject.minimum_balance = balance.minimum_balance
    },
    setLang(state, data) {
      state.currentLang = data
    },
  },
});
