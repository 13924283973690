var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.validDomain)?_c('div',{attrs:{"id":"app"}},[(_vm.$store.state.appLoading)?_c('Loading'):_vm._e(),(!_vm.$store.state.appLoading)?_c('v-app',[_c('v-main',[_c('router-view')],1),(
          _vm.$store.getters.getAuth &&
          [
            'NotFound',
            'ServerError',
            'Login',
            'Register',
            'Unsubscribe',
          ].includes(_vm.$route.name)
            ? false
            : true
        )?_c('AppBar'):_vm._e(),(
          _vm.$store.getters.getAuth &&
          [
            'NotFound',
            'ServerError',
            'Home',
            'Account',
            'Login',
            'Register',
            'Unsubscribe',
          ].includes(_vm.$route.name)
            ? false
            : true
        )?_c('SideBar'):_vm._e(),_c('OverlayLoading'),_c('TawkTo'),(_vm.migration)?_c('v-dialog',{attrs:{"max-width":"800","overlay-opacity":1,"overlay-color":"primary","persistent":""},model:{value:(_vm.migration),callback:function ($$v) {_vm.migration=$$v},expression:"migration"}},[_c('v-card',{staticClass:"rounded-xl",attrs:{"outlined":""}},[_c('Header',{attrs:{"deleteBtn":false,"closeBtn":false,"title":_vm.$t('WELCOME_TO_NEW_TELOBAL')}}),_c('v-card-text',{staticClass:"px-4 py-0 ma-0"},[_c('Migration',{on:{"close":function($event){_vm.migration = false}}})],1)],1)],1):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.invalidDomain)?_c('div',[_c('NotFound',{attrs:{"noButton":""}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }