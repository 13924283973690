<template>
  <!-- <v-row class="ma-0 pa-0" align="center" justify="center" dense>
    <v-col cols="12" class="pa-0 ma-0" v-if="message.length"> -->
        <!-- :height="this.message.length == 1 ? 0 : 60" -->
      <v-virtual-scroll
        v-if="message.length"
        item-height="12"
        height="50"
        :items="message"
        class="mt-0 mb-2"
      >
        <template v-slot="{ item }">
          <v-list-item
            class="rounded rounded-r-lg" 
            :style="
              $vuetify.rtl ?
                `border-right: 5px solid ${$vuetify.theme.themes.light.error}` :
                `border-left: 5px solid ${$vuetify.theme.themes.light.error}`
            "
            style="background-color: #EF3E3620;"
            dense
          >
          <!-- border-left: 3px solid red -->
            <v-list-item-content>
              <small class="font-weight-medium error--text" style="font-size: 10px">
                {{ item }}
              </small>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    <!-- </v-col>
  </v-row> -->
</template>

<script>
export default {
  install(Vue) {
    Vue.component("Errors", this);
  },

  props: {
    message: {
      type: Array,
      default: () => [],
    }, //this prop is error's messages
  },

  // data() {
  //   return {};
  // },
  // computed: {
  //   heightCompu() {
  //     if (this.message.length == 1) {
  //       return 30;
  //     } else if (this.message.length == 2) {
  //       return 30;
  //     }
  //   },
  // },
};
</script>
<style scoped>
.s{
  border-left: 3px solid red;
  background-color: #EF3E3652;
}
</style>
