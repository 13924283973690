import Axios from "axios";
import router from "./router";
import store from "./store";

const origin = process.env.VUE_APP_BACKEND_API
  ? process.env.VUE_APP_BACKEND_API
  : document.location.origin;
const apiPath = process.env.VUE_APP_BACKEND_API_PATH
  ? process.env.VUE_APP_BACKEND_API_PATH
  : "/api/v1/";
const baseUrl = origin + apiPath;

const Request = Axios.create({
  timeout: 10000,
  baseURL: baseUrl,
});

Request.urls = {
  configs: { // 1 Day
    site: "/site/", // GET
    unsubscribe: "/unsubscribe/", // GET, POST
    terms: "/terms/", // GET
  },
  auth: { // 1 Day
    login: "/login/", // POST
    logout: "/logout/", // GET
    register: "/register/", // POST
    activate: "/activate/", // GET
    change_password: "/change_password/", // POST
    forgot_password: "/forgot_password/", // POST
    reset_password: "/reset_password/", // GET, POST
    launch_pbx: "/pk/launch_pbx/", // GET
  },
  settings: { // 3 Day
    info: "/info/", // GET, POST
    geodata: "/geodata/", // GET, PATCH
    communications: "/communications/", // GET
    add_email: "/add_email/", // POST
    verify_email: "/verify_email/", // POST
    add_phone: "/add_phone/", // POST
    verify_phone: "/verify_phone/", // POST
    handler: "/handler/", // POST, DELETE
    company: "/company/", // GET, PATCH
    social_network: "/social_network/", // GET, PATCH
    newsletter: "/newsletter/", // GET, PATCH
    sessions: "/sessions/", // GET, DELETE
    migration: "/migration/", // GET, PATCH
  },
  project: { // 3 Day
    list: "/", // GET, POST
    details: "/pk/", // GET, PATCH
    simple_user: "/pk/simple_user/", // GET
    // subscriber_reset_password: "", // PATCH
    billing_address: "/pk/billing_address/", // GET, PATCH
  },
  dashboard: { // 1 Day
    details: "/pk/", // GET
  },
  dids: { // 1 Day
    countries: "/pk/countries/", // GET
    list: "/pk/buy/1/", // GET
    details: "/pk/buy/2/", // GET
    requirements: "/pk/buy/requirements/", // GET
    availables: "/pk/buy/random_did/", // GET
    info: "/pk/buy/3/", // GET
    submit: "/pk/buy/submit/", // POST
  },
  mynumbers: { // 3 Day
    list: "/pk/", // GET
    call_trunk: "/pk/call_trunk/", // GET, POST
    sms_trunk: "/pk/sms_trunk/", // GET, POST
    renew: "/pk/renew/", // GET, POST
    restore: "/pk/restore/", // GET, POST
    change_channels: "/pk/change_channels/", // GET, POST
    change_cycle: "/pk/change_cycle/", // GET, POST
    change_auto_renew: "/pk/change_auto_renew/", // POST
    cancel: "/pk/cancel/", // POST
    sms_inbox: "/pk/sms/inbox/", // GET, DELETE
    sms_send: "/pk/sms/send/", // POST
    sms_outbox: "/pk/sms/outbox/", // GET
    cdr: "/pk/cdr/", // GET
    sms_api: "/pk/sms/token/", // GET
  },
  rates: { // 0.5 Day
    pstn: "/pk/pstn/", // GET
    sms_out: "/pk/sms/out/", // GET
    sms_delivery: "/pk/sms/delivery/", // GET
  },
  cart: { // 1 Day
    details: "/pk/", // GET, DELETE
    pay: "/pk/process_pay/", // POST
  },
  // gateway: {
  //   callback: "/callback/pk/",
  // },
  callback: { // 0.3 Day
    list: "/pk/", // GET
    payment: "/pk/payment/",
    order: "/pk/order/",
  },
  finance: { // 6 Day
    reload_wallet: "/pk/wallet/", //GET
    wallet: "/pk/wallet/charge/", // GET, POST
    wire: "/pk/wire/", // GET, POST
    summary: "/pk/summary/", // GET
    orders: "/orders/pk/", // GET, DELETE
    item: "/orders/pk/item/", // DELETE
    repay: "/orders/pk/repay/", // POST
    invoices: "/pk/invoices/", // GET
    payments: "/pk/payments/", // GET
    persian_invoice: "/pk/payments/persian_invoice/", // GET
    transaction: "/pk/wallet/transactions/", // GET
  },
  media: {
    test: "/protect/wire/project/e703e98c-9bd2-4260-902c-b05bf5acea2e/408ce1c6-d9ff-4781-bf34-1fa95a07a682.png"
  },
};

Request.formatUrl = function (url, pk) {
  return url.replace(/pk/g, pk);
};

Request.getUrl = function (name, path) {
  let project = localStorage.getItem("project");
  let person = localStorage.getItem("person");
  let url = name + this.urls[name][path];
  url = url.replace(/pk/g, project);
  return person ? (url += `?person=${person}`) : url;
};

Request.Overlay = true;

Request.interceptors.request.use(
  (config) => {
    if (Request.Overlay) {
      store.commit("showOverlay", true);
    }
    let token = window.localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    // localStorage.setItem('myFavoriteSandwich', 'turkey')
    // config.headers["language"] = window.localStorage.getItem("language") || store.state.account.language
    config.onUploadProgress = function (progressEvent) {
      let uploadPercentage = parseInt(
        Math.round((progressEvent.loaded / progressEvent.total) * 100)
      );

      if (uploadPercentage === 100) store.commit("setOverlay", { percent: 0 });
      else store.commit("setOverlay", { percent: uploadPercentage });
    };
    return config;
  },
  (error) => {
    store.commit("showOverlay", false);
    return Promise.reject(error);
  }
);

Request.interceptors.response.use(
  function (response) {
    store.commit("showOverlay", false);
    return response;
  },
  function (error) {
    if (error.response) {
      // if (error.response.status == 400) {
      //   console.log("ghsdfghsdfsdhafgasdgfdashgasdfsdhgfsadhgasdfhgadsfsdahgfdshgdsafsdahg", error)
      // }
      if (error.response.status == 401) {
        // console.log("401", error.response);
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("project");
        // window.localStorage.removeItem("projectName");
        window.localStorage.removeItem("person");
        router.push({ path: "/login" });
        // window.location.href = "/login";
      }
      if (error.response.status == 403) {
        // console.log("403")
        router.push({ path: "/" });
      }
      if (error.response.status == 404) {
        // not found error
        if (process.env.NODE_ENV == "development") {
          console.log(
            "error.response.status=404 from cu_axios.js: ",
            error.response
          );
        } else {
          router.push({ name: "NotFound" });
        }
        // if (process.env.NODE_ENV === 'development') {
        //   router.push({ name: 'Home' });
        // }
      }
      if (error.response.status == 500) {
        // server error
        // console.log("error.response.status=500 from cu_axios.js: ", error);
        // router.push({ name: 'ServerError' })
        if (process.env.NODE_ENV == "development") {
          console.log(
            "error.response.status=500 from cu_axios.js: ",
            error.response
          );
        } else {
          router.push({ name: "ServerError" });
        }
      }
      if (error.response.status == 503) {
        window.location.reload()
      }
    }
    store.commit("showOverlay", false);
    return Promise.reject(error);
  }
);

export default Request;
