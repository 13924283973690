const LoginAs = {
  async created() {
    // console.log("this.$route.path", this.$route.path)
    if (["/as/", "/as"].includes(this.$route.path)) {
      await this.loginAs();
    }
  },

  methods: {
    async loginAs() {
      localStorage.clear()
      function getSearchParameters() {
        var prmstr = window.location.search.substr(1);
        return prmstr != null && prmstr != ""
          ? transformToAssocArray(prmstr)
          : {};
      }
      function transformToAssocArray(prmstr) {
        var params = {};
        var prmarr = prmstr.split("&");
        for (var i = 0; i < prmarr.length; i++) {
          var tmparr = prmarr[i].split("=");
          params[tmparr[0]] = tmparr[1];
        }
        return params;
      }
      var params = getSearchParameters();
      if (params.hasOwnProperty("token")) {
        localStorage.setItem("token", params.token);
      }
      if (params.hasOwnProperty("project")) {
        localStorage.setItem("project", params.project);
      }
      if (params.hasOwnProperty("person")) {
        localStorage.setItem("person", params.person);
      }
      if (params.hasOwnProperty("project")) {
        window.location.href = "/dashboard"
      } else {
        window.location.href = "/"
      }

      window.localStorage.setItem("as", 1)
    },
  },
};

export default LoginAs;
