<template>
  <v-tooltip
    :max-width="maxWidth"
    :min-width="minWidth"
    :top="tPos"
    :right="rPos"
    :bottom="bPos"
    :left="lPos"
    :color="desType.color"
  >
    <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            :color="hover ? desType.hColor : desType.color"
            :class="classIcon"
            :small="small"
            :x-small="xSmall"
            dark
            v-text="information ? 'mdi-information-outline' : desType.icone"
          />
        </v-hover>
    </template>
    <span>
      <span v-if="Boolean(message)" style="display: block !important;" v-html="message" />
      <slot name="message" />
    </span>
  </v-tooltip>
</template>

<script>
  export default {
    install (Vue) {
      Vue.component('Description', this)
    },

    props: {
      message: {
        type: String,
        default: null,
      }, //this prop is message of tooltip 
      type: {
        type: String,
        default: 'help'
      }, //this prop is type of decription 
      classIcon: {
        type: String,
        default: null
      },
      tPos: {
        type: Boolean,
        default: false
      }, //this prop is position of message 
      rPos: {
        type: Boolean,
        default: false
      }, //this prop is position of message
      bPos: {
        type: Boolean,
        default: false
      }, //this prop is position of message
      lPos: {
        type: Boolean,
        default: false
      }, //this prop is position of message
      small: {
        type: Boolean,
        default: false
      },
      "x-small": {
        type: Boolean,
        default: false
      },
      information: {
        type: Boolean,
        default: false
      },
      "min-width": {
        type: String,
        default: "",
      },
      "max-width": {
        type: String,
        default: "",
      },
    },

    data() {
      return { 
        help: {
          icone: "mdi-help-circle-outline",
          hColor: "#0082D9",
          color: "primary"
        },
        info: {
          // icone: "mdi-information-variant",
          icone: "mdi-lightbulb-on-outline",
          hColor: "#0082D9",
          color: "primary"
        },
        info2: {
          icone: "mdi-lightbulb-on-outline",
          hColor: "#D0A000",
          color: "warning"
        },
        // information: {
        //   icone: "mdi-information-outline",
        //   hColor: "#D0A000",
        //   color: "warning"
        // },
        success: {
          icone: "mdi-check-circle-outline",
          hColor: "#25A252",
          color: "success"
        },
        warning: {
          icone: "mdi-alert-outline",
          hColor: "#D0A000",
          color: "warning"
        },
        danger: {
          icone: "mdi-alert-octagon-outline",
          hColor: "#CB352E",
          color: "danger"
        }
      }
    },
    computed: {
      desType() {
        if(this.type === "help") {
          return this.help
        } else if(this.type === "info") {
          return this.info
        } else if(this.type === "info2") {
          return this.info2
        } else if(this.type === "success") {
          return this.success
        } else if(this.type === "warning") {
          return this.warning
        } else if(this.type === "danger") {
          return this.danger
        }
      }
    }
  }
</script>
<style scoped>
.pointer {
  cursor: pointer !important;
}
</style>

<!-- this is global component for desciption  -->
<!-- this component has 6 prop -->
<!-- message prop is main prop -->
<!-- type prop defined icone & color of this component -->
<!-- default of icone & color is help icone and primary color -->
<!-- tPos, rPos, bPos & lPos define a position of tooltip message -->
<!-- default of position is bottom -->
<!-- for define a global component:
      instead of name section in script tag use:
        install (Vue) {
          Vue.component('Description', this)
        },
      and in main.js add this:
        import Description from '.../address of global component'
        Vue.use(Description);
-->