<template>
  <div>
    <!-- domainm valid -->
    <div id="app" v-if="validDomain">
      <Loading v-if="$store.state.appLoading" />
      <v-app v-if="!$store.state.appLoading">
        <v-main>
          <router-view />
        </v-main>
        <AppBar
          v-if="
            $store.getters.getAuth &&
            [
              'NotFound',
              'ServerError',
              'Login',
              'Register',
              'Unsubscribe',
            ].includes($route.name)
              ? false
              : true
          "
        />
        <SideBar
          v-if="
            $store.getters.getAuth &&
            [
              'NotFound',
              'ServerError',
              'Home',
              'Account',
              'Login',
              'Register',
              'Unsubscribe',
            ].includes($route.name)
              ? false
              : true
          "
        />
        <OverlayLoading />
        <TawkTo />
        <!-- migration modal -->
        <v-dialog
          v-if="migration"
          v-model="migration"
          max-width="800"
          :overlay-opacity="1"
          overlay-color="primary"
          persistent
        >
          <v-card class="rounded-xl" outlined>
            <Header
              :deleteBtn="false"
              :closeBtn="false"
              :title="$t('WELCOME_TO_NEW_TELOBAL')"
            />
            <!-- <v-card-text class="pa-4 pb-0 ma-0 mt-4"> -->
            <v-card-text class="px-4 py-0 ma-0">
              <Migration @close="migration = false" />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-app>
    </div>
    <!-- domain not valid -->
    <div v-if="invalidDomain">
      <NotFound noButton />
    </div>
  </div>
</template>

<script>
// TODO : create default loading for all template and resellers
// TODO : simple 200 snackbar
import RouterCreator from "@/plugins/RouterCreator.js";
import methods from "@/core/js/methods";
import loginAs from "@/mixins/login_as";

export default {
  name: "App",

  components: {
    Loading: () => import("@/core/components/Loading.vue"),
    SideBar: () => import("@/themes/classic/components/desktop/main/SideBar.vue"),
    AppBar: () => import("@/themes/classic/components/desktop/main/AppBar.vue"),
    TawkTo: () => import("@/themes/classic/components/desktop/main/TawkTo.vue"),
    OverlayLoading: () => import("@/core/components/OverlayLoading.vue"),
    Migration: () => import("@/themes/classic/components/desktop/main/Migration.vue"),
    NotFound: () => import("@/themes/classic/views/NotFound.vue"),
  },

  mixins: [loginAs],

  data: () => ({
    validDomain: false,
    invalidDomain: false,
    migration: false,
  }),

  computed: {
    migrationShow() {
      return Boolean(+window.localStorage.getItem("as"))
        ? false
        : this.$store.getters.getState("account", "is_migrated");
    },
    getLang(){
      return this.$store.state.currentLang
    }
  },

  watch: {
    migrationShow: {
      immediate: true,
      handler() {
        this.migration = Boolean(+window.localStorage.getItem("as"))
          ? false
          : this.$store.getters.getState("account", "is_migrated");
      },
    },

    getLang: {
      immediate: true,
      handler() {
        if(localStorage.getItem("language") == 'fa'){
          this.$i18n.locale = 'fa'
          this.$vuetify.rtl = true
        }
        else if(localStorage.getItem("language") == 'en'){
        this.$i18n.locale = 'en'
        this.$vuetify.rtl = false
        }
      },
    },
  },

  async mounted() {
    this.$store.commit("setAppLoading", true);
    var iconURL = null;
    let url = this.$http.getUrl("configs", "site");
    await this.$http
      .get(url, {
        params: {
          identifier: localStorage.getItem("identifier"),
          notify: localStorage.getItem("notify"),
        },
      })
      .then((response) => {
        this.validDomain = true;
        this.invalidDomain = false;
        // console.log("response App.vue configs.site", response.data)
        this.$store.commit("setThemeName", response.data.default.toLowerCase());
        this.$store.commit("setAppAccess", response.data.access);
        this.$store.commit("setSiteKey", response.data.google_captcha_site_key);
        this.$store.commit("setUserAgent", response.data.user_agent);
        this.$store.commit("setAppConfig", response.data);
        this.$store.commit("setLanguages", response.data.languages);

        localStorage.setItem("identifier", response.data.identifier);

        if (response.data.hasOwnProperty("notify")) {
          this.wellcomeNote = response.data.notify;
          localStorage.setItem("notify", true);
        }

        if (response.data.hasOwnProperty("template")) {
          iconURL = response.data.template.images.icon;
          methods.localStorageSetter(response.data.template);
        } else {
          iconURL = this.$localStorageFinder("image_icon");
        }

        methods.vuetifySetter(this.$vuetify);

        RouterCreator(this.$router);


        // Head section
        document.title = response.data.brand;
        if(Boolean(iconURL)) {
          let siteLogo = document.createElement("link");
          siteLogo.setAttribute("rel", "icon");
          siteLogo.setAttribute("href", iconURL);
          siteLogo.setAttribute("type", "image/icon type");
          document.head.appendChild(siteLogo);
        }
        this.init();
      })
      .catch((error) => {
        if (process.env.NODE_ENV == "development") {
          // console.log("domain not found");
        } else {
          if(Boolean(error.response)) {
            this.invalidDomain = true
            this.validDomain = false
          }
        }
      })
      .finally((_) => {
        this.$store.commit("setAppLoading", false);
      });
  },

  methods: {
    async init() {
      if (localStorage.getItem("token")) {
        let url = this.$http.getUrl("settings", "info");
        await this.$http.get(url).then((response) => {
          this.$store.commit("setForceChangePassword", response.data.force_change_password)
          this.$store.commit("setVerifiedPhone", response.data.verified_phone)
          this.$store.commit("setPersianInvoice", response.data.persian_invoice)
          this.$store.commit("setAccount", response.data.client)
          // this.$i18n.locale = response.data.client.language
          this.$store.commit("setCurrency", response.data.currency)
          this.$store.commit("setGlobalStatus", response.data.global_status)
          this.$store.commit("setAuth", true)
          this.$store.commit("setAppLoading", false)

          // if (!localStorage.getItem("project") && !["/account", "/unsubscribe"].includes(this.$router.history.current.path)) {
          if (!localStorage.getItem("project") && !["Account", "Unsubscribe", "NotFound"].includes(this.$router.history.current.name)) {
            this.$router.push({ name: "Home" });
          }
        });

        if (Boolean(localStorage.getItem("project"))) {
          let url = this.$http.getUrl("project", "details");
          await this.$http.get(url).then((response) => {
            this.$store.commit("setCurrentProject", response.data)
          });
        }
      } else if (
        ![
          "/register",
          "/activation",
          "/forgot_password",
          "/reset_password",
        ].includes(this.$router.history.current.path)
      ) {
        this.$router.push({ name: "Login" });
      }
    },
  },
};
</script>


