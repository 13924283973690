import i18n from "@/plugins/i18n";
import store from "@/plugins/store";

var routes = [
  {
    path: "/",
    name: "Home",
    params: {loginCheck: false},
    component: () => import(`@/themes/${store.state.themeName}/views/Home.vue`),
    meta: {
      title: i18n.t("HOME_PAGE_TITLE"),
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(`@/themes/${store.state.themeName}/views/NotFound.vue`),
    meta: {
      title: i18n.t("NOT_FOUND_PAGE_TITLE")
    },
  },
];

var Packages = [];

function RouteCreator(
  routes = [],
  meta = {},
  regex = { sensitive: false, strict: false, end: false }
) {
  routes.forEach((route) => {
    var capRoute = "";
    if (Boolean(route.match(/\/:/))) {
      route
        .split("/")[0]
        .split("_")
        .forEach((element) => {
          capRoute += element.charAt(0).toUpperCase() + element.slice(1);
        });
      // capRoute += route.split(":")[1].charAt(0).toUpperCase() + route.split(":")[1].slice(1)
      let dynamicSection =''
      let dynamicSectionArray = route.split(":")
      dynamicSectionArray.shift()
      dynamicSectionArray.forEach(section => {
        dynamicSection += section.charAt(0).toUpperCase() + section.slice(1)
      })
      capRoute += dynamicSection.replaceAll('/', '')
    } 
    else if(Boolean(route.match(/\//))) {
      // console.log("routeroute", route)
      route.split("/").forEach((element) => {
        capRoute += element.charAt(0).toUpperCase() + element.slice(1);
      })
    }
    else {
      route.split("_").forEach((element) => {
        capRoute += element.charAt(0).toUpperCase() + element.slice(1);
      });
    }
    // console.log("capRoute", capRoute)
    let newMeta = structuredClone(meta);
    newMeta["title"] = i18n.t(
      `${route.split("/")[0].toUpperCase()}_PAGE_TITLE`
    );
    Packages.push({
      path: `/${route}`,
      name: `${capRoute}`,
      component: () =>
        import(`@/themes/${store.state.themeName}/views/${capRoute}.vue`),
      meta: newMeta,
      pathToRegexpOptions: regex,
    });
  });
}

RouteCreator(["register", "activation", "unsubscribe"]);
RouteCreator([
  "login",
  "forgot_password",
  "reset_password",
  "dashboard",
  "project_settings",
  "my_number",
  "my_module"
]);
RouteCreator(["buy_number/:country/:did", "buy_number/:country", "buy_number"]);
RouteCreator(["customer_care"]);
RouteCreator(["account", "finance", "cart"]);
RouteCreator(["sms"]);
RouteCreator(["order/result"]);
RouteCreator(["call_detail_record"]);
RouteCreator(["rate"]);
RouteCreator(["server_error"]);


// 

function access(name) {
  let accessKeys = Object.keys(store.state.appAccess);
  if (accessKeys.includes(name)) {
    return store.state.appAccess[name];
  }
  var result = undefined;
  accessKeys.forEach((key) => {
    if (name.startsWith(key)) {
      result = store.state.appAccess[key];
    }
  });
  return result;
}

const RouterCreator = function (router) {
  // var routes = []
  Packages.forEach((pack) => {
    if ([true, undefined].includes(access(pack.name))) {
      routes.push(pack);
    }
  });
  // routes.find(route => route.name == "Home").path = '/'
  // console.log("routes", routes)
  routes.forEach((route) => {
    router.addRoute(route);
  });

  // console.log("routes", routes)
  // console.log("routerrouterrouterrouterrouter", router)
};

export default RouterCreator;
